import React from 'react'
import Wrapper from './Wrapper.js'

export default () => (
  <Wrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
			<path d="M495.2,312.6l-49.5-22c22.4-87.6-21-180.9-106.3-218.8c-61.2-27.1-129.1-19-180.9,15.6l36.4,51.4c34.4-22.2,78.9-27.3,119.1-9.4c53.4,23.6,81.9,80.2,72.9,135.3l-50.1-22.5c-6.3-2.8-10.1,0.4-8.2,7l34.6,127.7c1.8,6.7,8.3,9.6,14.5,6.4l117.9-60.1C501.7,320.1,501.6,315.3,495.2,312.6z"/>
			<path d="M186,370.7c-53.4-23.6-81.9-80.2-72.9-135.3l50.1,22.2c6.3,2.8,10.1-0.4,8.2-7l-34.6-127.5c-1.8-6.7-8.3-9.6-14.5-6.4L4.6,176.7c-6.2,3.1-6,8,0.3,10.8l49.5,22c-22.4,87.6,21,180.9,106.3,218.8c61.2,27.1,129.1,19,180.9-15.6l-36.4-51.4C270.7,383.4,226.2,388.6,186,370.7z"/>
    </svg>
  </Wrapper>
)